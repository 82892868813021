
<template>
  <div class="h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row w-full">
      <FinalForm
        :submit="saveData"
        :initialValues="initialValues"
        class="w-full form-col-2 pr-2"
        ref="form"
      >
        <div class="stick bg-white pb-3 z-10">
          <div class="form-row">
            <label class="font-semibold" style="color: var(--highlightColor500)"
              >attachments
            </label>
          </div>
        </div>
        <div>
          <label class="form-section-sub-title">owner's representative</label>

          <div class="form-row">
            <TextField
              name="ownersAddress.addressLine1"
              label="street line 1"
              :validate="required"
            />
          </div>
          <div class="form-row">
            <TextField
              name="ownersAddress.addressLine2"
              label="street line 2"
            />
          </div>
          <div class="form-row">
            <TextField
              name="ownersAddress.city"
              label="city"
              :validate="required"
            />
            <StateInput
              name="ownersAddress.state"
              label="state"
              :validate="required"
            />
            <TextField
              name="ownersAddress.zip"
              label="zip"
              type="number"
              :validate="required"
            />
          </div>
          <div class="form-row">
            <TextField
              name="ownersAddress.telephone"
              label="telephone"
            />
            <TextField name="ownersAddress.fax" label="fax" />
            <TextField
              name="ownersAddress.afterHoursTelephone"
              label="after hours telephone"
            />
          </div>
          <div class="h-4"></div>
          <label class="form-section-sub-title">locator service</label>

          <div class="form-row">
            <TextField name="locatorName" label="locator name" />
            <TextField name="locatorPhone" label="locator phone" />
          </div>
          <div class="form-row">
            <TextField
              name="locatorAddress.addressLine1"
              label="street line 1"
              :validate="required"
            />
          </div>
          <div class="form-row">
            <TextField
              name="locatorAddress.addressLine2"
              label="street line 2"
            />
          </div>
          <div class="form-row">
            <TextField
              name="locatorAddress.city"
              label="city"
              :validate="required"
            />
            <StateInput
              name="locatorAddress.state"
              label="state"
              :validate="required"
            />
            <TextField
              name="locatorAddress.zip"
              label="zip"
              type="number"
              :validate="required"
            />
          </div>

          <div class="h-4"></div>

          <label class="form-section-sub-title">copies and attachments</label>

          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="accessGateAddendum"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">access gate addendum</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="additionalSpecialProvisions"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">additional special provisions</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="animalAddendum"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">animal addendum</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="allocationAddendum.allocationCheckbox"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">allocation addendum for:</div>
          </div>
          <div class="form-row flex-wrap">
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="allocationAddendum.gas"
                />
              </button>
              <div class="text-title mr-8">gas</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="allocationAddendum.water"
                />
              </button>
              <div class="text-title mr-8">water</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="allocationAddendum.electricity"
                />
              </button>
              <div class="text-title mr-8">electricity</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="allocationAddendum.centralSystemCosts"
                />
              </button>
              <div class="text-title mr-8">central system costs</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="allocationAddendum.trashRecycling"
                />
              </button>
              <div class="text-title mr-8">trash/recycling</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="allocationAddendum.cableSatellite"
                />
              </button>
              <div class="text-title mr-8">cable/satellite</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="allocationAddendum.stormwaterDrainage"
                />
              </button>
              <div class="text-title mr-8">stormwater/drainage</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="allocationAddendum.servicesGovernmentFees"
                />
              </button>
              <div class="text-title mr-8">services/government fees</div>
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="apartmentRules"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">
              appartment rules or community policies
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="asbestosAdendum"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">
              asbestos addendum (if asbestos is present)
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="bedBugAddendum"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">bed bug addendum</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="earlyTerminationAddendum"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">early termination addendum</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="enclosedGarageAddendum"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">
              enclosed garage, carport or storage unit addendum
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="intrusionAlarmAddendum"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">intrusion alarm addendum</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="inventoryForm"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">inventory and condition form</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="leadHazardAddendum"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">
              lead hazard info and disclosure addendum
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="leaseContractAddendum"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">
              lease contract addendum for units participating in government
              regulated affordable housing programs
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="leaseContractGuaranty"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">lease contract guaranty</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="legalDescription"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">
              legal description of apartment (optional, if rental term longer
              than one year)
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="militarySCRAAddendum"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">military SCRA addendum</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="moldAddendum"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">
              mold information and prevention addendum
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="moveOutCleaning"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">move-out cleaning instructions</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="noticeOfIntentToMoveOut"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">notice of intent to move-out form</div>
          </div>
          <div class="form-row flex-wrap">
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="parkingPermit"
                  style="margin-left: 0"
                />
              </button>
              <div class="text-title mr-8">
                parking permit or sticker (quantity:
                <span
                  ><TextInputSmall
                    name="parkingPermitQuantity"
                    type="number" /></span
                >)
              </div>
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="rentConsessionsAddendum"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">rent consession addendum</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="rentersLiabilityAddendum"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">
              renter's or liability insurance addendum
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="repairRequestForm"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">repair or service request form</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="satelliteDishAddendum"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">
              satellite dish or antenna addendum
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="securityGuidelinesAddendum"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">security guidelines addendum</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="PUCTenantAllocation"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">
              PUC tenant guide to water allocation
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="utilities.utilitySubmeteringAddendumCheckbox"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">utility submetering addendum for:</div>
          </div>
          <div class="form-row flex-wrap">
            <div class="form-row">
              <button>
                <CheckboxInputSmall
                  type="checkbox"
                  name="utilities.electricity"
                />
              </button>
              <div class="text-title mr-8">electricity</div>
            </div>
            <div class="form-row">
              <button>
                <CheckboxInputSmall type="checkbox" name="utilities.water" />
              </button>
              <div class="text-title mr-8">water</div>
            </div>
            <div class="form-row" style="margin-bottom: 1rem">
              <button>
                <CheckboxInputSmall type="checkbox" name="utilities.gas" />
              </button>
              <div class="text-title mr-8">gas</div>
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="otherCheckbox1"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title">other</div>

            <TextField name="other1" />
            <button>
              <CheckboxInputSmall type="checkbox" name="otherCheckbox2" />
            </button>
            <div class="text-title">other</div>

            <TextField name="other2" />
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="otherCheckbox3"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title">other</div>

            <TextField name="other3" />
            <button>
              <CheckboxInputSmall type="checkbox" name="otherCheckbox4" />
            </button>
            <div class="text-title">other</div>

            <TextField name="other4" />
          </div>
        </div>
      </FinalForm>
      <modal-footer :footer="footer" :primary="saveData"></modal-footer>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import TextField from "@/components/form/TextField";
import StateInput from "@/components/form/StateInput";
import TextInputSmall from "@/components/form/TextInputSmall";
import CheckboxInputSmall from "@/components/form/CheckboxInputSmall";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import { FinalForm } from "vue-final-form";

export default {
  name: "OtherCharges",
  components: {
    Loader,
    ModalFooter,
    TextField,
    TextInputSmall,
    CheckboxInputSmall,
    StateInput,
    FinalForm,
  },
  mixins: [ModalNavigation, InitializeFormMixin],
  data() {
    return {
      loading: false,
      footer: {
        primaryButton: "save",
      },
      ownersAddress: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: "",
        telephone: "",
        fax: "",
        afterHoursTelephone: "",
      },
      locatorName: "",
      locatorPhone: "",
      locatorAddress: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: "",
      },
      accessGateAddendum: "",
      additionalSpecialProvisions: "",
      animalAddendum: "",
      allocationAddendum: {
        allocationCheckbox: "",
        gas: "",
        water: "",
        electricity: "",
        centralSystemCosts: "",
        trashRecycling: "",
        cableSatellite: "",
        stormwaterDrainage: "",
        servicesGovernmentFees: "",
      },
      apartmentRules: "",
      asbestosAdendum: "",
      bedBugAddendum: "",
      earlyTerminationAddendum: "",
      enclosedGarageAddendum: "",
      intrusionAlarmAddendum: "",
      inventoryForm: "",
      leadHazardAddendum: "",
      leaseContractAddendum: "",
      legalDescription: "",
      militarySCRAAddendum: "",
      moldAddendum: "",
      moveOutCleaning: "",
      noticeOfIntentToMoveOut: "",
      parkingPermit: "",
      parkingPermitQuantity: "",
      rentConsessionsAddendum: "",
      rentersLiabilityAddendum: "",
      repairRequestForm: "",
      satelliteDishAddendum: "",
      securityGuidelinesAddendum: "",
      PUCTenantAllocation: "",
      utilities: {
        utilitySubmeteringAddendumCheckbox: "",
        electricity: "",
        water: "",
        gas: "",
      },
      otherCheckbox1: "",
      other1: "",
      otherCheckbox2: "",
      other2: "",
      otherCheckbox3: "",
      other3: "",
      otherCheckbox4: "",
      other4: "",
    };
  },
  computed: {
    initialValues() {
      return {
        ownersAddress: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zip: "",
          telephone: "",
          fax: "",
          afterHoursTelephone: "",
        },
        locatorName: "",
        locatorPhone: "",
        locatorAddress: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zip: "",
        },
        accessGateAddendum: "",
        additionalSpecialProvisions: "",
        animalAddendum: "",
        allocationAddendum: {
          allocationCheckbox: "",
          gas: "",
          water: "",
          electricity: "",
          centralSystemCosts: "",
          trashRecycling: "",
          cableSatellite: "",
          stormwaterDrainage: "",
          servicesGovernmentFees: "",
        },
        apartmentRules: "",
        asbestosAdendum: "",
        bedBugAddendum: "",
        earlyTerminationAddendum: "",
        enclosedGarageAddendum: "",
        intrusionAlarmAddendum: "",
        inventoryForm: "",
        leadHazardAddendum: "",
        leaseContractAddendum: "",
        legalDescription: "",
        militarySCRAAddendum: "",
        moldAddendum: "",
        moveOutCleaning: "",
        noticeOfIntentToMoveOut: "",
        parkingPermit: "",
        parkingPermitQuantity: "",
        rentConsessionsAddendum: "",
        rentersLiabilityAddendum: "",
        repairRequestForm: "",
        satelliteDishAddendum: "",
        securityGuidelinesAddendum: "",
        PUCTenantAllocation: "",
        utilities: {
          utilitySubmeteringAddendumCheckbox: "",
          electricity: "",
          water: "",
          gas: "",
        },
        otherCheckbox1: "",
        other1: "",
        otherCheckbox2: "",
        other2: "",
        otherCheckbox3: "",
        other3: "",
        otherCheckbox4: "",
        other4: "",
      };
    },
  },
  methods: {
    saveData() {
      // Logic here
    },
  },
};
</script>

<style scoped>
input:checked {
  accent-color: var(--highlightColor500) !important;
}

.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
button {
  outline: none;
  box-shadow: none;
}
</style>
